import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import { family, student } from '../../../../helper/api_url';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { swal } from '../../../../helper/swal';
import CustomSelect from '../../../../components/CustomSelect';
import ChangeCourseModal from '../../Course/TabPanes/ChangeCourseModal';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../../features/Ui/uiSlice';
export const UpdateStudentOtherDetails = ({ data }) => {
    const queryClient = useQueryClient();
    const dispatch=useDispatch();
    const { data: families = [], } = useQuery({
        queryKey: ['families'],
        queryFn: () => family.getAll(),
        select: (res) => res.data.family,
        staleTime: 20 * 60 * 1000,
        gcTime: 20 * 60 * 1000
    })
    const options = [{ label: "Genral", value: "gen" }, { label: "OBC", value: "obc" }, { label: "Backward Class", value: "bc" }, { label: "SC", value: "sc" }, { label: "ST", value: "st" }, { label: "Other", value: "other" },];
    const getDefaultOption = (category) => { return options.find(option => option.value === category) || { label: "", value: "" }; };
    const mutation = useMutation({
        mutationFn: (formData) => student.update(formData),
        onMutate: () => {dispatch(setPreloader({ loader: true, message: 'please wait ... ' }));},
        onSuccess: (res) => { queryClient.invalidateQueries(['students', data?.id]); swal.success(res.data.message); },
        onError: (err) => { swal.error(err.response ? err.response.data.message : err.message); },
        onSettled: () => {dispatch(setPreloader({ loader: false, message: '' }));},
    });
    const handleUpdateStudentOther = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        mutation.mutate(formData);
    }
    return (
        <>
            <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12">
                <div className="card mt-4 shadow-sm">
                    <div className="card-header text-white text-center">
                        <h2 className='mb-1 text-center text-primary'>Other Details</h2>
                    </div>
                    <div className='card-body my-0'>
                        <div className='row mt-0 justify-content-between'>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="class" className="form-label">Class</label>
                                    <input type="text" className="form-control" value={data?.courses?.find(i=>i.pivot.status==='active')?.name || 'No course assigned'} placeholder="" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className='mt-4 py-2'>
                                    <ChangeCourseModal studentData={data} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleUpdateStudentOther}>
                        <div className="card-body mt-0">
                            <div className='row mt-0 justify-content-between'>
                                <input type="hidden" name="user_id" defaultValue={data?.id} />
                                <div className="col-12">
                                    <div>
                                        <label className='control-label'>Select/Search A Family</label>
                                        <CustomSelect
                                            name="family_id"
                                            options={families.map(f => ({ label: `${f?.father_name} (${f?.father_mobile})`, value: f.id }))}
                                            isSearchable
                                            placeholder='search family by phone number'
                                            defaultValue={{ value: data?.personal_details?.parents?.id, label: data?.personal_details?.parents?.father_name + '( ' + data?.personal_details?.parents?.father_mobile + ' )' }}
                                        />
                                    </div>
                                </div>
                                <div className="col-6 mt-3">
                                    <div>
                                        <label htmlFor="adhaar" className="form-label">Aadhar No</label>
                                        <input type="text" className="form-control" id='adhaar' name="adhaar" defaultValue={data?.personal_details?.adhaar} placeholder="" />
                                    </div>
                                </div>
                                <div className="col-6 mt-3">
                                    <div>
                                        <label htmlFor="category" className="form-label">category</label>
                                        <CustomSelect
                                            options={options}
                                            defaultValue={getDefaultOption(data?.personal_details?.category)}
                                            isSearchable
                                            name="category"
                                        />
                                    </div>
                                </div>
                                {/* 
                                <div className="col-6 mt-3">
                                    <div>
                                        <label htmlFor="height" className="form-label">Height</label>
                                        <input type="text" className="form-control" id='height' name="height" defaultValue={data?.personal_details?.height} placeholder="" />
                                    </div>
                                </div>
                                <div className="col-6 mt-3">
                                    <div>
                                        <label htmlFor="weight" className="form-label">Weight</label>
                                        <input type="text" className="form-control" id='weight' name="weight" defaultValue={data?.personal_details?.weight} placeholder="" />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='d-flex justify-content-center'>
                                <button type="submit" className="btn btn-primary">Update OtherDeatils</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
