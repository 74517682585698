import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BreadCrumb from '../../../../components/common/BreadCrumb';
import { useQuery } from '@tanstack/react-query';
import { student } from '../../../../helper/api_url';
import { swal } from '../../../../helper/swal';
import { UpdateStudentProfile } from './UpdateStudentProfile';
import { UpdateStudentOtherDetails } from './UpdateStudentOtherDetails';
import { UpdateStudentAddress } from './UpdateStudentAddress';

export const UpdatestudentAdmission = () => {
    const { studentId } = useParams();
    const { data: students = [], isLoading, isError, error } = useQuery({
        queryKey: ['students', studentId],
        queryFn: () => student.profile(studentId),
        staleTime: 20 * 60 * 1000,
        gcTime: 20 * 60 * 1000,
        select:(data)=>data?.data?.profile
    });
    useEffect(() => { if (isError) swal.error(error.response ? error.response.data.message : error.message) }, [isError, error])
  return (
    <>
        <BreadCrumb title="Addmission Details" prevPage="Home" prevPath="/admission" />
        <h1 className="text-center mb-1">Update Student Details</h1>
        <div className='row justify-content-center'>
            <UpdateStudentProfile  data={students}/>
            <UpdateStudentOtherDetails studentId={studentId}  data={students} />
            <UpdateStudentAddress studentId={students} />
        </div>
    </>
  );
};


