import { Button, CardSubtitle, CardTitle, Col, Row } from 'react-bootstrap'
import { Card, CardBody, CardHeader } from 'react-bootstrap'
import { TableResponsive } from '../../../../components/common/TableResponsive'
import { course, marksheet_api } from '../../../../helper/api_url'
import { useMemo, useEffect, useState } from 'react'
import { swal } from '../../../../helper/swal'
import CustomSelect from '../../../../components/CustomSelect'
import BreadCrumb from '../../../../components/common/BreadCrumb'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { printer } from '../../../../helper/printer'
import { SessionYear } from '../../../../helper/formatDate'


const CreateMarksheet = () => {
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [studentList, setStudentList] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [fullMarks, setFullMarks] = useState(100);
    const [passMarks, setPassMarks] = useState(33);
    const queryClient = useQueryClient();
    const coursesApi = useQuery({
        queryKey: ['courses'],
        queryFn: course.list,
        select:(res)=> res.data.course,
        cacheTime:1800000,
        staleTime:1800000,
    });

    const studentApi = useQuery({ 
        queryKey: ['students'],
        queryFn:()=>course.students(selectedCourse?.value),
        select:(res)=>res.data.student,
        enabled:selectedCourse?.value != null,
        cacheTime:1800000,
        staleTime:1800000,
    });

    // marksheetApi.data = selected course full marksheet details (all subjects)
    const marksheetApi = useQuery({
        queryKey: ['marksheets', selectedCourse?.value, selectedSubject],
        queryFn:()=> marksheet_api.courseMarksheet(selectedCourse?.value),
        select: (res) => res.data,
        enabled: selectedCourse?.value != null && selectedSubject != null,
        cacheTime:1800000,
        staleTime:1800000,
    })
    useEffect(() => {
        setTableLoading(coursesApi.isLoading || studentApi.isLoading);
    }, [coursesApi.isLoading, studentApi.isLoading]);

    useEffect(() => {
        if(coursesApi.isError){
            swal.error(coursesApi.error.response ? coursesApi.error.response.data.message : coursesApi.error.message)
        }
        if(studentApi.isError){
            swal.error(studentApi.error.response ? studentApi.error.response.data.message : studentApi.error.message)
        }
        if(marksheetApi.isError){
            swal.error(marksheetApi.error.response ? marksheetApi.error.response.data.message : marksheetApi.error.message)
        }
    },[coursesApi.isError, studentApi.isError, marksheetApi.isError]);

    const subjectList = ['English','Math','Science','Social Science', 'Hindi', 'Computer','Sanskrit','Environmental Studies (EVS)','General Knowledge (GK)','Drawing', 'Music','Physical Education', ];

    const columns = useMemo(() => [
        {
            Header: 'Admission ID',
            accessor: 'id',
        },
        {
            Header: 'Student Name',
            accessor: 'first_name',
            Cell: ({ row }) => {
                return row.original.first_name + ' ' + row.original.last_name
            }
        },
        {
            Header: 'Father Name',
            accessor: 'personal_details.family.father_name',
        },
        {
            Header: 'Full Marks ',
            accessor: 'full_marks',
            Cell: ({ row }) => {
                return <input
                    type="number"
                    key={row.id}
                    className="form-control w-100"
                    name={'full_marks[' + row.original.id + ']'}
                    defaultValue={fullMarks}
                    readOnly
                />
            }
        },
        {
            Header: 'Pass Marks',
            accessor: 'pass_marks',
            Cell: ({ row }) => {
                return <input
                    type="number"
                    className="form-control w-100"
                    name={'passing_marks[' + row.original.id + ']'}
                    defaultValue={passMarks}
                    readOnly
                />
            }
        },
        {
            Header: 'obtained Marks',
            accessor: 'marks',
            Cell: ({ row }) => {
                const marks = marksheetApi.data?.find((item) => (item.user_id === row.original.id && item.subject === selectedSubject))?.marks || '';
                return <input
                    type="number"
                    className="form-control w-100"
                    name={'marks[' + row.original.id + ']'}
                    defaultValue={marks}
                />
            }
        },
        {
            accessor: 'action',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            Cell: ({ row }) => {
                const address = row.original.addresses[0];
                return <div className="d-none">
                    <input type="hidden" name={"subject[" + row.original.id + "]"} value={selectedSubject || ''} />
                    <input type="hidden" name={"grade[" + row.original.id + "]"} value={selectedCourse?.value || ''} />
                    <input type="hidden" name={"father_name[" + row.original.id + "]"} value={row.original?.personal_details?.family.father_name || 'father_name'} />
                    <input type="hidden" name={"mother_name[" + row.original.id + "]"} value={row.original?.personal_details?.family.mother_name || 'mother_name'} />
                    <input type="hidden" name={"address[" + row.original.id + "]"} value={
                        address?.addr1 + ', ' +
                        address?.addr2 + ', ' +
                        address?.city + ', ' +
                        address?.district + ', ' +
                        address?.state + ', ' +
                        address?.pin || ''
                    } />
                </div>
            }
        },
        {
            Header: 'List',
            accessor: 'list',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                return <div className="d-none">Please use a bigger screen to view this list</div>
            }
        }
    ], [passMarks, fullMarks,selectedCourse?.value, selectedSubject, marksheetApi.data]);

    const marksheetMutation = useMutation({
        mutationFn: (formData)=> {
            console.log(formData);
            return marksheet_api.create(formData)
        },
        onSuccess: (res) => {
            queryClient.invalidateQueries(['marksheets', selectedCourse?.value, selectedSubject]);
            swal.success(res.message)
        },
        onError: (err) => {
            swal.error(err.response ? err.response.data.message : err.message)
        }
    })

    const handleSubmit = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const marksheet = [];
        studentApi.data?.forEach(student => {
            const entry = {
                user_id: student.id,
                full_marks: formData.get(`full_marks[${student.id}]`),
                passing_marks: formData.get(`passing_marks[${student.id}]`),
                marks: formData.get(`marks[${student.id}]`),
                // marks_fa1: formData.get(`marks_fa1[${student.id}]`),
                // marks_sa1: formData.get(`marks_sa1[${student.id}]`),
                subject: selectedSubject,
                grade: selectedCourse.label,
                father_name: formData.get(`father_name[${student.id}]`),
                mother_name: formData.get(`mother_name[${student.id}]`),
                address: formData.get(`address[${student.id}]`),
                attendance: '0',
            }
            marksheet.push(entry);
        });
        marksheetMutation.mutate({ marksheet })

        // marksheet_api.create({ marksheet })
        //     .then(res => 
        //         swal.success(res.message))
        //     .catch(e => swal.error(e.response ? e.response.data.message : e.message))
    }


    return (
        <>
            <BreadCrumb title="Marksheet" prevPage="Home" prevPath="/dashboard" />
            <Card>
                <CardHeader>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <CardTitle> Create Marksheet </CardTitle>
                            <CardSubtitle className='mt-3'> In this section, you can Update or Create Marksheet for specific students </CardSubtitle>
                        </div>
                        <div><h3>Periodic II </h3></div>
                        <div></div>
                        <div></div>
                    </div>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={6} className="mb-2">
                            <label>Class</label>
                            <CustomSelect
                                disabled={coursesApi.isLoading}
                                options={coursesApi.data?.map(c => ({ label: c.name, value: c.id }))}
                                placeholder={coursesApi.isLoading ? 'Loading...' : 'Select Class'}
                                onChange={(e)=>setSelectedCourse(e)}
                            />
                        </Col>
                        <Col md={6} className="mb-2">
                            <label>Subject</label>
                            <CustomSelect
                                onChange={(e) => setSelectedSubject(e.value)}
                                options={subjectList.map(c => ({ label: c, value: c }))}
                                placeholder="select Subject"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="mb-2">
                            <label>Full Marks</label>
                            <input
                                type="number"
                                className="form-control"
                                name="full_marks"
                                value={fullMarks}
                                onChange={(e) => setFullMarks(e.target.value)}
                            />
                        </Col>
                        <Col xs={6} className="mb-2">
                            <label>Pass Marks</label>
                            <input
                                type="number"
                                className="form-control"
                                name="passing_marks"
                                value={passMarks}
                                onChange={(e) => setPassMarks(e.target.value)}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Marks Input</h5>
                            <Button disabled={!selectedCourse?.value} onClick={()=>printer.handleMarkSheetByClass(selectedCourse?.value)} className='btn btn-soft-primary'>Print Marksheet</Button>
                        </CardHeader>
                        <CardBody className="d-none d-lg-block">
                            <form onSubmit={handleSubmit}>

                                <TableResponsive
                                    isLoading={tableLoading}
                                    columns={columns}
                                    data={selectedSubject == null || selectedCourse == null ? [] : studentApi.data}
                                    noRecord="No Record Found"
                                    customPageSize={1000}
                                />
                                <button type="submit" disabled={marksheetMutation.isPending} className="btn btn-primary">Submit</button>
                            </form>
                        </CardBody>
                        <CardBody className="d-lg-none">
                            <form onSubmit={handleSubmit}>
                                {studentApi.data?.map((student) => (
                                    <div key={student.id} className="mb-5 border p-3 rounded">
                                        <h3>#{student.id} - {student.first_name} {student.last_name}</h3>
                                        <div className="d-flex gap-3">
                                            <p>{student.personal_details?.family?.father_name}</p>
                                            <p>{selectedCourse?.label}</p>
                                        </div>
                                        <hr />
                                        <Row>
                                            <Col xs={4} className="mb-3">
                                                <label className="form-label">Subject</label>
                                                <input 
                                                    type="text" 
                                                    className='form-control' 
                                                    name={`subject[${student.id}]`} 
                                                    readOnly 
                                                    defaultValue={selectedSubject || ''} 
                                                />
                                            </Col>
                                            <Col xs={4} className="mb-3">
                                                <label className="form-label">Full Marks</label>
                                                <input
                                                    type="number"
                                                    key={student.id}
                                                    className="form-control w-100"
                                                    name={'full_marks[' + student.id + ']'}
                                                    defaultValue={fullMarks}
                                                    readOnly
                                                />
                                            </Col>
                                            <Col xs={4} className="mb-3">
                                                <label className="form-label white-space-nowrap">Passing Marks</label>
                                                <input
                                                    type="number"
                                                    key={student.id}
                                                    className="form-control w-100"
                                                    name={'passing_marks[' + student.id + ']'}
                                                    defaultValue={passMarks}
                                                    readOnly
                                                />
                                            </Col>
                                            <Col xs={6} className="mb-3">
                                                <label className="form-label">Marks Obtained</label>
                                                <input
                                                    type="number"
                                                    className="form-control w-100 bg-soft-success"
                                                    name={'marks[' + student.id + ']'}
                                                    defaultValue={marksheetApi.data?.find((item) => (item.user_id === student.id && item.subject === selectedSubject))?.marks || ''}
                                                />
                                            </Col>
                                        </Row>
                                        <div className="d-none">
                                            <input type="hidden" name={`grade[${student.id}]`} value={selectedCourse?.value || ''} />
                                            <input type="hidden" name={`father_name[${student.id}]`} value={student?.personal_details?.family?.father_name || 'father_name'} />
                                            <input type="hidden" name={`mother_name[${student.id}]`} value={student?.personal_details?.family?.mother_name || 'mother_name'} />
                                            <input type="hidden" name={`address[${student.id}]`} value={
                                                (student.addresses && student.addresses[0]) ?
                                                    `${student.addresses[0].addr1}, ${student.addresses[0].addr2}, ${student.addresses[0].city}, ${student.addresses[0].district}, ${student.addresses[0].state}, ${student.addresses[0].pin}` :
                                                    ''
                                            } />
                                        </div>
                                    </div>
                                ))}
                                <div className='d-flex justify-content-center'>
                                <button disabled={marksheetMutation.isPending} type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default CreateMarksheet;
