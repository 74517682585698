import React, { useEffect, useState } from 'react';
import { useModalHandler } from '../../../helper/custom_hook';
import { Modal, Row, Col } from 'react-bootstrap';
import CustomSelect from '../../../components/CustomSelect';
import { course, family, student } from '../../../helper/api_url';
import DataLoading from '../../../components/DataLoading';
import { swal } from '../../../helper/swal';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import NewFamilyModel from './new-family-model';


function AddStudentFamilyModal({ studentId }) {
    const { status, toggleModal } = useModalHandler();
    const [isLoading, setIsLoading] = useState(true);
    const [students, setStudents] = useState([]);
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    // fetching and caching courses
    const { data: courses = [], isLoading: coursesLoading, isError: isCoursesError, error: coursesError } = useQuery({
        queryKey: ['courses'],
        queryFn: () => course.list(),
        select: (res) => res.data.course,
        staleTime: 20 * 60 * 1000,
        gcTime: 20 * 60 * 1000
    })
    // fetching and caching families
    const { data: families = [], isLoading: familiesLoading, isError: isFamiliesError, error: familiesError } = useQuery({
        queryKey: ['families'],
        queryFn: () => family.getAll(),
        select: (res) => res.data.family,
        staleTime: 20 * 60 * 1000,
        gcTime: 20 * 60 * 1000
    })
    // loading and error handling
    useEffect(() => {
        setIsLoading(coursesLoading || familiesLoading)
        if (isCoursesError || isFamiliesError) {
            const error = coursesError.response ? coursesError.response.data.message : coursesError.message
                + ' | ' + familiesError.response ? familiesError.response.data.message : familiesError.message
            swal.error(error)
        }
    }, [courses, families]);
    // handle change family | calling change family api
    const handleChangeFamily = e => {
        e.preventDefault();
        dispatch(setPreloader({ loader: coursesLoading || familiesLoading, message: 'please wait ... ' }));
        const formData = new FormData(e.target);
        student.otherDetails(formData).then(r => {
            swal.success(r.message, 'Done');
            queryClient.invalidateQueries(['students','user',studentId]);
            toggleModal();
        })
            .catch(e => swal.error(e.response ? e.response.data.message : e.message))
            .finally(() => dispatch(setPreloader({ loader: false, message: '' })))

    }

    return (
        <>
            <button onClick={toggleModal} className="btn btn-outline-success rounded-circle d-flex justify-content-center align-items-center shadow" style={{ width: '30px', height: '30px', padding: '0' }}><i className="bx bx-plus" style={{ fontSize: '24px' }}></i></button>
            <Modal className="fade" size='md' centered={true} backdrop="static" show={status} onHide={toggleModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Assign Family</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleChangeFamily}>
                    <Modal.Body>
                        {isLoading ? (<DataLoading />) : (
                            <>
                                <Row className="row justify-content-center align-items-center">
                                    <input type="hidden" name="user_id" value={studentId} />
                                    <div className="col-6 mt-2">
                                        <label htmlFor="category" className="form-label">Category <small className="text-danger">*</small></label>
                                        <CustomSelect
                                            name="category"
                                            isSearchable
                                            options={[
                                                { label: "Genral", value: "gen" },
                                                { label: "OBC", value: "obc" },
                                                { label: "Backward Class", value: "bc" },
                                                { label: "SC", value: "sc" },
                                                { label: "ST", value: "st" },
                                                { label: "other", value: "other" },
                                            ]}
                                        />
                                    </div>
                                    <div className="col-6 mt-2">
                                        <label htmlFor="caste" className="form-label">Religion <small className="text-danger">*</small></label>
                                        <input type="text" className="form-control" id="caste" name="caste" />
                                    </div>
                                    <div className="col-6 mt-2">
                                        <label htmlFor="bloodgroup" className="form-label">Blood Group</label>
                                        <input type="text" className="form-control" id="bloodgroup" name="bloodgroup" />
                                    </div>

                                    <div className="col-6 mt-2">
                                        <label htmlFor="height" className="form-label">Height</label>
                                        <input type="text" className="form-control" id="height" name="height" />
                                    </div>

                                    <div className="col-6 mt-2">
                                        <label htmlFor="weight" className="form-label">Weight</label>
                                        <input type="text" className="form-control" id="weight" name="weight" />
                                    </div>

                                    <div className="col-6 mt-2">
                                        <label htmlFor="vision_left" className="form-label">Vision Left</label>
                                        <input type="text" className="form-control" id="vision_left" name="vision_left" />
                                    </div>

                                    <div className="col-6 mt-2">
                                        <label htmlFor="vision_right" className="form-label">Vision Right</label>
                                        <input type="text" className="form-control" id="vision_right" name="vision_right" />
                                    </div>

                                    <div className="col-6 mt-2">
                                        <label htmlFor="dentalhygeine" className="form-label">Dental Hygeine</label>
                                        <input type="text" className="form-control" id="dentalhygeine" name="dentalhygeine" />
                                    </div>

                                    <div className="col-12 mt-2">
                                        <label htmlFor="adhaar" className="form-label">Adhar Number <small className="text-danger">*</small></label>
                                        <input type="number" className="form-control" id="adhaar" name="adhaar" />
                                    </div>

                                    <Col lg={8} xs={6} className='mb-4 mt-2' >
                                        <div>
                                            <label className='control-label'>Select/Search A Family</label>
                                            <CustomSelect
                                                name="family_id"
                                                options={families.map(f => ({ label: `${f?.father_name} (${f?.father_mobile})`, value: f.id }))}
                                                isSearchable
                                                placeholder='search family by phone number'
                                            />
                                        </div>
                                    </Col>
                                    <div className="col-lg-4 col-xs-6 mt-2"><NewFamilyModel /></div>
                                </Row>
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-soft-info btn-label right ms-auto" >
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i> Change / Save
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default AddStudentFamilyModal
