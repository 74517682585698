import React, { useEffect, useMemo, useState } from 'react'
import { useModalHandler } from '../../../../helper/custom_hook';
import { Modal, Row, Col } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CustomSelect from '../../../../components/CustomSelect';
import { course, fee, student } from '../../../../helper/api_url';
import { swal } from '../../../../helper/swal';
import { TableResponsive } from '../../../../components/common/TableResponsive';

export const StudentClass = ({ studentId }) => {
    const { toggleModal, status } = useModalHandler();
    const queryClient = useQueryClient();
    const { data: courses = [], isLoading, isError, error } = useQuery({
        queryKey: ['course'],
        queryFn: () => course.list(),
        staleTime: 20 * 60 * 1000,
        gcTime: 20 * 60 * 1000,
    });
    const [selectedCourseFees, setSelectedCourseFees] = useState([]);
    const [allFees, setAllFees] = useState([]);
    const mutation = useMutation({
        mutationFn: (formData) => student.enroll(formData),
        onSuccess: (res) => {
            queryClient.invalidateQueries(["students"]);
            swal.success(res.data.message);
            toggleModal();
        },
        onError: (err) => { swal.error(err.response ? err.response.data.message : err.message); }
    });
    useEffect(() => {
        fee.list().then((r) => setAllFees(r.data.fee)).catch((e) => console.error(e));
    }, []);
    const handleEnrollClass = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const enrollmentData = {
			user_id: formData.get("user_id"),
			fees: selectedCourseFees.map((fee) => ({
				fee_id: fee.id,
				final_amount: e.target[`fees_final_${fee.id}`].value,
			})),
			class_id: formData.get("class_id"),
			section: formData.get("section"),
		};
        mutation.mutate(enrollmentData);
    }
    const fees_table_columns = useMemo(() => [
        {
            Header: "Fees Name",
            accessor: "name",
        },
        {
            Header: "Fees Type",
            accessor: "type",
            HeaderClass: "text-center",
            DataClass: "text-center",
        },
        {
            Header: "Amount",
            accessor: "amount",
            HeaderClass: "text-center",
            DataClass: "text-center",
        },
        {
            Header: "Final Amount",
            HeaderClass: "text-center",
            DataClass: "text-center",
            Cell: (cell) => (
                <div className="d-flex align-items-center justify-content-center">
                    <input
                        className="form-control"
                        style={{ maxWidth: "100px" }}
                        defaultValue={cell.row.original.amount}
                        name={"fees_final_" + cell.row.original.id}
                    />
                </div>
            ),
        },
        {
            Header: "Action",
            Cell: (cell) => (
                <button
                    type="button"
                    onClick={() => handleFeeDeletionFromSelectedCourseFee(cell.row.original.id)}
                    className="btn btn-sm btn-soft-danger"
                >
                    <i className="ri-delete-bin-fill"></i>
                </button>
            ),
            HeaderClass: "text-center",
            DataClass: "text-center",
        },
        {
            Header: "List",
            HeaderClass: "d-none",
            DataClass: "d-none",
            list: (row) => (
                <div className="d-flex">
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#" className="link text-dark"></a>
                            <a href="#">
                                {row.name} {row.amount}
                            </a>
                        </h5>
                    </div>
                </div>
            ),
        },
    ]);
    const handleFeeDeletionFromSelectedCourseFee = (fee_id) => {
        if (fee_id) {
            if (!allFees.some((i) => i.id == fee_id))
                setAllFees([
                    ...allFees,
                    ...selectedCourseFees.filter((f) => f.id == fee_id),
                ]);
            setSelectedCourseFees((fees) => fees.filter((f) => f.id != fee_id));
        }
    };
    const handleFeeAdditionToSelectedCourseFee = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        if (formData.get("fee_id")) {
            if (!selectedCourseFees.some((i) => i.id == formData.get("fee_id")))
                setSelectedCourseFees([
                    ...selectedCourseFees,
                    ...allFees.filter((f) => f.id == formData.get("fee_id")),
                ]);
            setAllFees((fees) => fees.filter((f) => f.id != formData.get("fee_id")));
        }
    };
    return (
        <>
            <button onClick={toggleModal} className="btn btn-outline-success rounded-circle d-flex justify-content-center align-items-center shadow" style={{ width: '30px', height: '30px', padding: '0', }}><i className="bx bx-plus" style={{ fontSize: '24px', }}></i></button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal} >
                <Modal.Header closeButton>
                    <Modal.Title><h5>Enroll Class</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <form onSubmit={handleFeeAdditionToSelectedCourseFee}>
                            <div className="row justify-content-between align-items-center mb-3">
                                
                                    <div className='col-lg-8'>
                                        <label htmlFor="fees" className="form-label"> Fees{" "}</label>
                                        <CustomSelect isSearchable name="fee_id" id="fees"options={allFees.map((i) => ({label: i.name,value: i.id,}))}/>
                                    </div>
                                
                                    <div className="col-lg-4">
                                        <label style={{ opacity: 0 }}  htmlFor="classes"  className="form-label" >Select Section{" "}</label>
                                        <button type="submit" className="form-control btn btn-soft-warning" > Add Fee</button>
                                    </div>
                               
                            </div>
                        </form>
                        <form onSubmit={handleEnrollClass}>
                            <input
                                type="hidden"
                                name="user_id"
                                value={studentId}
                            />
                            <Row className="pb-4">
                                <Col xs={6} className="mb-3">
                                    <div>
                                        <label htmlFor="classes" className="form-label">
                                            Select A Class{" "}
                                        </label>
                                        <CustomSelect
                                            isSearchable
                                            name="class_id"
                                            id="classes"
                                            options={courses?.data?.course?.map((i) => ({
                                                label: i.name,
                                                value: i.id,
                                            }))}
                                            onChange={(e) => {
                                                const newFees = courses?.data?.course?.filter(
                                                    (course) => course.id === e.value
                                                )[0]?.fees;
                                                setSelectedCourseFees([
                                                    ...selectedCourseFees,
                                                    ...newFees.filter(
                                                        (newFee) =>
                                                            !selectedCourseFees.some(
                                                                (existingFee) =>
                                                                    existingFee.id === newFee.id
                                                            )
                                                    ),
                                                ]);
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col xs={6} className="mb-3">
                                    <div>
                                        <label htmlFor="classes" className="form-label">
                                            Select Section{" "}
                                        </label>
                                        <CustomSelect
                                            isSearchable
                                            name="section"
                                            id="classes"
                                            options={[
                                                { label: "A", value: "a" },
                                                { label: "B", value: "b" },
                                                { label: "C", value: "c" },
                                                { label: "D", value: "d" },
                                                { label: "E", value: "e" },
                                            ]}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <TableResponsive
                                        showCustomOptionPage={false}
                                        showFilter={false}
                                        isPagination={false}
                                        columns={fees_table_columns}
                                        data={selectedCourseFees}
                                        noRecord="Selected Course does not have a Fee"
                                    />
                                </Col>
                                <div className="d-flex justify-content-center align-items-center mt-4">
                                    <button type="submit" className="btn btn-primary">Enroll</button>
                                </div>
                            </Row>
                        </form>

                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
