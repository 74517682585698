
import LZString from 'lz-string';
/**
 * Printer class provides methods to open various pages related to school management in a new browser window.
 * It includes methods for handling invoices, admit cards, demand bills, and marksheets.
 * 
 * @class
 * @example
 * const printer = new Printer();
 * 
 * // To handle an invoice
 * printer.handleInvoice(123);
 * 
 * // To handle an admit card by class
 * printer.handleAdmitCardByClass(10);
 * 
 * // To handle an admit card by student
 * printer.handleAdmitCardByStudent(456);
 * 
 * // To handle a demand bill for a specific month and UPI ID
 * printer.handleDemandBill(0, 'upi-id@xyz');
 * 
 * // To handle a demand bill for a specific month, family, and UPI ID
 * printer.handleDemandBillByFamily(0, 789, 'upi-id@xyz');
 * 
 * // To handle a demand bill for a specific month, class, and UPI ID
 * printer.handleDemandBillByClass(0, 10, 'upi-id@xyz');
 * 
 * // To handle a demand bill for a specific month, student, and UPI ID
 * printer.handleDemandBillByStudent(0, 456, 'upi-id@xyz');
 * 
 * // To handle a marksheet by class
 * printer.handleMarkSheetByClass(10);
 * 
 * // To print a custom endpoint with parameters
 * printer.print('/custom-endpoint', { param1: 'value1', param2: 'value2' });
 */
class Printer {
    #url = `https://management.unboxschool.in/printer`;
    #privateQuery;
    constructor() {
        const token = LZString.compressToEncodedURIComponent(localStorage.getItem("_token"));
        const schoolCode = encodeURIComponent(window.__school_code);
        this.#privateQuery = `?&s=${schoolCode}&t=${token}`;
    }
    #fire (endpoint='',params={}) {
        let url = this.#url + endpoint + this.#privateQuery;
        Object.keys(params).forEach(key => {
            if(params[key] !== undefined && params[key] !== null) url += `&${key}=${params[key]}`;
        });
        const popup = window.open(url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=800,height=600');
        if (!popup) throw new Error(`Popup blocked! Please enable pop-ups for this site.`);
    }
    /**
     * Opens a payment receipt page for the given invoice.
     * @param {number} i - Invoice ID.
     */
    handleInvoice = (i) => this.#fire('/payment-receipt/',{i});

    /**
     * Opens the admit card page for a specific class.
     * @param {number} c - Class ID.
     */
    handleAdmitCardByClass = (c) => this.#fire('/admit-card/by-class.php',{c});
    /**
     * Opens the admit card page for a specific student.
     * @param {number} st - Student ID.
     */
    handleAdmitCardByStudent = (st) => this.#fire('/admit-card/by-student.php',{st}); 

    /**
     * Opens the demand bill page for a specific month with upi QR code.
     * @param {number} m - Month (0-based, e.g., 0 for January).
     * @param {string} u - UPI ID.
     */
    handleDemandBill = (m,u) => this.#fire('/demand-bill/',{m:m+1,u});
    /**
     * Opens the demand bill page for a specific month, and family with upi QR code.
     * @param {number} m - Month (0-based, e.g., 0 for January).
     * @param {number} f - Family ID.
     * @param {string} u - UPI ID.
     */
    handleDemandBillByFamily = (m,f,u=null) => this.#fire('/demand-bill/',{m:m+1,f,u});
    /**
     * Opens the demand bill page for a specific month, and class with upi QR code.
     * @param {number} m - Month (0-based, e.g., 0 for January).
     * @param {number} c - Class ID.
     * @param {number} u - UPI ID.
     */
    handleDemandBillByClass = (m,c,u=null) => this.#fire('/demand-bill/',{m:m+1,c,u});
    /**
     * Opens the demand bill page for a specific month, and student with upi QR code.
     * @param {number} m - Month (0-based, e.g., 0 for January).
     * @param {number} st - Student ID.
     * @param {number} u - UPI ID.
     */
    handleDemandBillByStudent = (m,st,u=null) => this.#fire('/demand-bill/',{m:m+1,st,u});

    /**
     * Opens the marksheet page for a specific class.
     * @param {number} c - Class ID.
     */
    handleMarkSheetByClass = (c) => this.#fire('/marksheet/index.php',{c}); 
    /**
     * Opens the page for a specific printer endpoint and parameters.
     * @param {string} endpoint - starts with / .
     * @param {Object} params - parameters to be sent as query string
     */
    print = (endpoint,params) => this.#fire(endpoint,params);
}
export const printer = new Printer();
