import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { allUser } from '../../../helper/api_url';
import { ASSET_URL } from '../../../helper/api_url'
import Camera from '../../../components/common/Camera';
import SimpleBar from 'simplebar-react';
import ModalProfileImage from '../../../components/common/ModalProfileImage';
import { useModalHandler, useWebCam } from '../../../helper/custom_hook';
export function UpdateAllUserModal({ data, apiHandler }) {
    const { genders, toggleModal, status, userProfile, setUserProfile } = useModalHandler();
    const { webcam, toggleWebcam, setCapturedImageToInput } = useWebCam(setUserProfile);
    useEffect(() => {
        const viewImage = ASSET_URL + data.avatar;
        setUserProfile(viewImage);
    }, []);
    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-success me-1'><i className="ri-pencil-fill"></i></button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Update User</h5></Modal.Title>
                </Modal.Header>
                <form onSubmit={e => apiHandler.handleUpdate(e, allUser.update, toggleModal)}>
                    <SimpleBar id="scrollbar" style={{ maxHeight: "calc(90vh - 100px)" }} >
                        <Modal.Body>
                            <div className='position-relative'>
                                {webcam ? (
                                    <div className='position-absolute top-0 bottom-0 d-flex justify-content-center align-items-center start-0 end-0 modal-content border-0' style={{ zIndex: 9 }}>
                                        <Camera onSave={(file) => { setCapturedImageToInput(file, document.getElementById('avatarInput')); toggleWebcam() }} onClose={() => toggleWebcam()} />
                                    </div>) : null}
                                <div className="row g-3">
                                    {userProfile && <ModalProfileImage viewimage={userProfile} />}
                                    <input type="hidden" name="user_id" defaultValue={data.id} />

                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="firstName" className="form-label">First Name</label>
                                            <input type="text" className="form-control" id='firstName' name="first_name" defaultValue={data.first_name} placeholder="Enter firstname" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div>
                                            <label htmlFor="lastName" className="form-label">Last Name</label>
                                            <input type="text" className="form-control" id='lastName' name="last_name" defaultValue={data.last_name} placeholder="Enter lastname" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <label htmlFor="genderInput" className="form-label">Gender</label>
                                        <select id="genderInput" name='gender' defaultValue='male' className='form-control'>
                                            {data.gender && <option defaultValue={data.gender}>{data.gender}</option>}
                                            {genders.length ? genders.map((gender, idx) => (<option key={idx} value={gender.value}>{gender.label}</option>)) : (<option disabled >No user Gender Found</option>)}
                                        </select>
                                    </div>
                                    <div className='col-12'>
                                        <label htmlFor="dob" className="form-label">Date Of Birth</label>
                                        <input type="date" id="dob" name='dob' defaultValue={data.dob} className='form-control' />
                                    </div>
                                    <div className='col-10'>
                                        <label htmlFor="avatarInput" className="form-label">Profile Image</label>
                                        <input type="file" name="avatar" id="avatarInput" onChange={(e) => setCapturedImageToInput(e.target.files[0], document.getElementById('avatarInput'))} className='form-control' />
                                    </div>
                                    <div className='col-2'>
                                        <label htmlFor="avatarInput" className="form-label ">Capture</label>
                                        <button onClick={toggleWebcam} type='button' className='btn btn-soft-dark form-control'><i className='bx bx-camera'></i></button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </SimpleBar>
                    <Modal.Footer>
                        <div className="col-lg-12">
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}