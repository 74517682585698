import React from 'react'
import { useModalHandler } from '../../../../helper/custom_hook';
import { Col, Modal, Row } from 'react-bootstrap';
import { ASSET_URL } from '../../../../helper/api_url';
import ModalProfileImage from '../../../../components/common/ModalProfileImage';

export const ViewStudentList = ({data}) => {
    const {status, setStatus,toggleModal} = useModalHandler();
    const viewimage = ASSET_URL + data?.avatar;
  return (
    <>
    {
        <button onClick={toggleModal} className='btn btn-sm btn-soft-info me-1'>
            <i className="ri-eye-fill" />
        </button>
    }
    <Modal className="fade" centered={true} show={status} onHide={toggleModal}>
        <Modal.Header closeButton>
            <Modal.Title><h5>Student Details </h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <ModalProfileImage viewimage={viewimage} />
            <Row className='px-2 text-center mt-2'>
                <Col xs={12} >
                    <ul className="list-group">
                        <li className='list-group-item'>
                            <Row>
                                <Col xs={5}><span className='fw-bold'>Student ID </span></Col>
                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                <Col className='text-start' xs={5}><span className='text-wrap'> #{data?.id}</span></Col>
                            </Row>
                        </li>
                        <li className='list-group-item'>
                            <Row>
                                <Col xs={5}><span className='fw-bold'>Student Name </span></Col>
                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                <Col className='text-start' xs={5}><span className='text-wrap'> {data?.first_name} {data?.last_name}</span></Col>
                            </Row>
                        </li>
                        <li className='list-group-item'>
                            <Row>
                                <Col xs={5}><span className='fw-bold'>Father Name </span> </Col>
                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                <Col className='text-start' xs={5}>
                                    <span className='text-nowrap'> {data?.personal_details?.family?.father_name} </span>
                                </Col>
                            </Row>
                        </li>
                        <li className='list-group-item'>
                            <Row>
                                <Col xs={5}><span className='fw-bold'>Email </span> </Col>
                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                <Col className='text-start' xs={5}>
                                    <span className='text-nowrap'> {data?.email} </span>
                                </Col>
                            </Row>
                        </li>
                        <li className='list-group-item'>
                            <Row>
                                <Col xs={5}><span className='fw-bold'>Mobile </span> </Col>
                                <Col xs={2}><span className='fw-bold'>:</span></Col>
                                <Col className='text-start' xs={5}>
                                    <span className='text-nowrap'> {data?.phone} </span>
                                </Col>
                            </Row>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>
</>
  )
}
