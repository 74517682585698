import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Card, Button } from "react-bootstrap";
import PieChart from '../../../helper/Charts/PieChart';
import DoughnutChart from '../../../helper/Charts/DoughnutChart';
import { primary, info, success, warning, gray_900, danger } from '../../../helper/Colors';
import { ASSET_URL, dashboard } from '../../../helper/api_url';
import CustomSelect from '../../../components/CustomSelect';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { TableResponsive } from '../../../components/common/TableResponsive';
import { handleViewInvoice } from '../../../helper/view-invoice';
import { Bar, Doughnut, Line, PolarArea } from 'react-chartjs-2';
import { ChalkboardTeacher, EnvelopeSimple, Student } from 'phosphor-react';
import userDefault from '../../../assets/images/users/user-dummy-img.jpg'
function Index() {
    const AuthUser = useSelector(state => state.auth);
    const [pieChartData, setPichartData] = useState({ labels: [], datasets: [] })
    const [dashboardData, setDashboardData] = useState([]);
    const [recentTransactions, setRecentTransactions] = useState([]);
    const [duration, setDuration] = useState('daily');
    const [datas, setDatas] = useState({ labels: [], datasets: [] });
    const [mydata, setMonthlyFees] = useState({ labels: [], datasets: [] });
    const months = ["December", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November",];
    let year = 2024;
    const formattedMonths = months.map((month, index) => {
        if (month === "January") year++;
        const monthNumber = (index === 0 ? 12 : index).toString().padStart(2, '0');
        return `${year}-${monthNumber}`;
    });
    useEffect(() => {
        if (!dashboardData) return;
        const summary = dashboardData?.attendanceData?.monthly_summary || {};
        const monthlyFeesInfo = dashboardData?.feesData?.monthly_dues || [];
        const monthlyExpense = dashboardData?.feesData?.monthly_expenses || [];
        const monthlyCollection = dashboardData?.feesData?.monthly_fees_payments || [];
        const totalDues = monthlyFeesInfo.reduce((acc, { month, total_dues }) => ({ ...acc, [month]: total_dues || 0 }), {});
        const totalExpenses = monthlyExpense.reduce((acc, { month, total_expenses }) => ({ ...acc, [month]: total_expenses || 0 }), {});
        const totalCollection = monthlyCollection.reduce((acc, { month, total_collections }) => ({ ...acc, [month]: total_collections || 0 }), {});
        const getMonthlyDues = () => formattedMonths.map((month) => totalDues[month] || 0);
        const getMonthlyExpenses = () => formattedMonths.map((month) => totalExpenses[month] || 0);
        const getMonthlyCollection = () => formattedMonths.map((month) => totalCollection[month] || 0);
        const getMonthlyProfit = () => { return getMonthlyCollection().map((collection, index) => collection - getMonthlyExpenses()[index]); };
        const monthlyFeesData = {
            labels: months.map((month) => month.slice(0, 3)),
            datasets: [
                { label: "Total Dues", data: getMonthlyDues(), borderColor: "rgb(240, 101, 72)", fill: false, tension: 0.1, },
                { label: "Collection", data: getMonthlyCollection(), borderColor: "rgb(41, 156, 219)", fill: false, tension: 0.1, },
                { label: "Expense", data: getMonthlyExpenses(), borderColor: "rgb(247, 184, 75)", fill: false, tension: 0.1, },
                { label: "Profit", data: getMonthlyProfit(), borderColor: "rgb(10, 179, 156)", fill: false, tension: 0.1, },
            ],
        };
        const getMonthlyData = (type) => months.map((month) => summary[month]?.[type] || 0);
        const attendanceData = {
            labels: months.map((month) => month.slice(0, 3)),
            datasets: [
                { label: "Present", backgroundColor: "rgb(10, 179, 156)", data: getMonthlyData("present"), },
                { label: "Absent", backgroundColor: "rgb(240, 101, 72)", data: getMonthlyData("absent"), },
                { label: "Leave", backgroundColor: "rgb(247, 184, 75)", data: getMonthlyData("leave"), },
                { label: "Unmarked", backgroundColor: "rgb(33, 37, 41)", data: getMonthlyData("unmarked"), },
            ],
        };
        setMonthlyFees(monthlyFeesData);
        setDatas(attendanceData);
    }, [dashboardData]);
    const TotalUser = {
        labels: ['Admin', 'Addmission', 'Staff', 'Class', 'Enquiry'],
        datasets: [{
            label: 'No.',
            data: [dashboardData?.userData?.no_of_admins, dashboardData?.userData?.no_of_students, dashboardData?.userData?.no_of_staffs, dashboardData?.classData?.total_class, dashboardData?.enquiryData],
            backgroundColor: [
                'rgb(255, 99, 132)',
                "rgb(10, 179, 156)",
                'rgb(255, 205, 86)',
                'rgb(33, 37, 41)',
                'rgb(54, 162, 235)'
            ]
        }]
    };
    useEffect(() => {
        dashboard.data().then(res => {
            setDashboardData(res.data)
            setRecentTransactions(res.data?.feesData?.recent_transactions);
        }).catch(e => console.log(e));
    }, []);

    const columns = useMemo(() => [
        {
            Header: "Name",
            Cell: (cell) => {
                const imageUrl = ASSET_URL + cell.row.original.payer?.avatar;
                return (<span>
                    <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={cell.row.original.payer?.avatar ? imageUrl : userDefault} alt="User Avatar" />
                    {cell.row.original.payer_name}
                </span>)
            },
        },
        {
            Header: "Details",
            Cell: cell => {
                const row = cell.row.original;
                return <div className='d-flex justify-content-between'>
                    <span>{row?.total_amount} ({row?.payment_mode})</span>
                    <Button onClick={(e) => handleViewInvoice(row.id)} className="btn btn-sm btn-soft-info me-1">
                        <i className="ri-eye-fill" /> View Details
                    </Button>
                </div>
            }
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: row => {
                const imageUrl = ASSET_URL + row.payer?.avatar;
                return <div className='d-flex justify-content-between'>
                    <div className='d-flex align-items-center gap-4'>
                        <span><img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={imageUrl} alt="User Avatar" />
                            {row.payer_name}</span>
                        <span className='text-muted'>{row.total_amount} ({row.payment_mode})</span>
                    </div>
                    <Button onClick={(e) => handleViewInvoice(row.id)} className="btn btn-sm btn-soft-info me-1">
                        <i className="ri-eye-fill" />
                    </Button>
                </div>
            }
        }

    ], [])
    useEffect(() => {
        setPichartData({
            labels: dashboardData?.attendanceData
                ? Object.keys(dashboardData?.attendanceData)?.filter(i => i !== 'no_of_students')?.map(d => d.split('_')[2]?.toUpperCase())
                : [],
            datasets: [{
                label: 'No of Students',
                data: dashboardData?.attendanceData
                    ? Object.keys(dashboardData?.attendanceData)?.filter(i => i !== 'no_of_students')?.map(d => dashboardData?.attendanceData[d])
                    : [],
                backgroundColor: [success, danger, warning, primary],
            }]
        })
    }, [dashboardData])
    const getFees = () => {
        return duration == 'daily'
            ? dashboardData?.feesData?.fees_payment_today
            : (duration == 'monthly' ? dashboardData?.feesData?.fees_payment_month : dashboardData?.feesData?.fees_payment_year)
    }
    const getExpense = () => {
        return duration == 'daily'
            ? dashboardData?.feesData?.expense_today
            : (duration == 'monthly' ? dashboardData?.feesData?.expense_month : dashboardData?.feesData?.expense_year)
    }
    return (
        <>
            {AuthUser?._user?.role?.priority >= 80 && (
                <Row className='overflow-auto d-flex custom-scrollbar mb-4'>
                    <Col xs={12} className='d-flex py-1 mb-2 justify-content-between align-items-center'>
                        <h3>Fees Information</ h3>
                        <div className="" style={{ width: '200px' }}>
                            <CustomSelect defaultValue={{ label: 'Daily', value: 'daily' }} onChange={e => setDuration(e.value)} options={[
                                { label: 'Daily', value: 'daily' },
                                { label: 'Monthly', value: 'monthly' },
                                { label: 'Yearly', value: 'yearly' },
                            ]} />
                        </div>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Row>
                            <Col lg={6} xs={6} className='mb-4'>
                                <Link to="/dues-list">
                                    <div className="py-4 px-3 border border-dashed border-3 rounded card-hover" style={{ minWidth: '150px' }}>
                                        <h5 className="text-muted text-uppercase fs-13"> Total Dues</h5>
                                        <div className="d-flex align-items-center">
                                            <div className="bg-danger rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                                <i className="ri-bank-fill display-6 text-white"></i>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h2 className="mb-0">
                                                    <span className="counter-value">
                                                        {dashboardData?.feesData?.dues}
                                                    </span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={6} xs={6} className='mb-4'>
                                <Link to="/dues-list">
                                    <div className="py-4 px-3 border border-dashed border-3 rounded card-hover" style={{ minWidth: '150px' }}>
                                        <h5 className="text-muted text-uppercase fs-13"> Collection</h5>
                                        <div className="d-flex align-items-center">
                                            <div className="bg-info rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                                <i className="ri-wallet-3-fill display-6 text-white"></i>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h2 className="mb-0">
                                                    <span className="counter-value" >
                                                        {getFees()}
                                                    </span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={6} xs={6} className='mb-4'>
                                <Link to="/dues-list">
                                    <div className="py-4 px-3 border border-dashed border-3 rounded card-hover" style={{ minWidth: '150px' }}>
                                        <h5 className="text-muted text-uppercase fs-13"> Expense</h5>
                                        <div className="d-flex align-items-center">
                                            <div className="bg-warning rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                                <i className="ri-refund-2-line display-6 text-white"></i>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h2 className="mb-0">
                                                    <span className="counter-value">
                                                        {getExpense()}
                                                    </span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={6} xs={6} className='mb-4'>
                                <Link to="/dues-list">
                                    <div className="py-4 px-3 border border-dashed border-3 rounded card-hover" style={{ minWidth: '150px' }}>
                                        <h5 className="text-muted text-uppercase fs-13"> Profit</h5>
                                        <div className="d-flex align-items-center">
                                            <div className="bg-success rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                                <i className="ri-coins-fill display-6 text-white"></i>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h2 className="mb-0">
                                                    <span className="counter-value" >
                                                        {parseFloat(getFees() || 0) - parseFloat(getExpense() || 0)}
                                                    </span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Card className='p-3'>
                            <Card.Header className='m-0'>Total Profit</Card.Header>
                            <div className="d-flex justify-content-center" >
                                <Line data={mydata} />
                            </div>
                        </Card>
                    </Col>
                </Row>
            )}

            {AuthUser?._user?.role?.priority >= 60 && (
                <Row className="overflow-auto d-flex custom-scrollbar mb-2">
                    <Col xs={12} className='d-flex py-1 mb-2 justify-content-between align-items-center'>
                        <h3>Attendance Information</ h3>
                        <div className="" style={{ width: '200px' }}>
                            <CustomSelect defaultValue={{ label: 'Today', value: 'today' }} options={[
                                { label: 'Today', value: 'today' },
                                { label: 'Yesterday', value: 'yesterday' },
                                { label: '2 days ago', value: '2dayago' },
                            ]} />
                        </div>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Row>
                            <Col className='mb-2' lg={12} xs={6}>
                                <Link to="/attendance">
                                    <div className="py-4 px-3 border border-dashed border-3 rounded card-hover" style={{ minWidth: '200px' }}>
                                        <h5 className="text-muted text-uppercase fs-13"> Total Students
                                            <i className="ri-arrow-up-circle-line text-success fs-18 px-3 float-left align-middle"></i>
                                        </h5>
                                        <div className="d-flex align-items-center">
                                            <div className="bg-info rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                                <i className="ri-team-line display-6 text-white"></i>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h2 className="mb-0"><span className="counter-value" data-target={dashboardData?.userData?.no_of_students}>{dashboardData?.userData?.no_of_students}</span></h2>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className='mb-2' lg={6} xs={6}>
                                <Link to="/attendance">
                                    <div className="py-4 px-3 border border-dashed border-3 rounded card-hover" style={{ minWidth: '200px' }}>
                                        <h5 className="text-muted text-uppercase fs-13"> Present Students <i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                                        <div className="d-flex align-items-center">
                                            <div className="bg-success rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                                <i className=" ri-user-follow-fill display-6 text-white"></i>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h2 className="mb-0"><span className="counter-value" data-target={dashboardData?.attendanceData?.no_of_present_students}>{dashboardData?.attendanceData?.no_of_present_students}</span></h2>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className='mb-2' lg={6} xs={6}>
                                <Link to="/attendance">
                                    <div className="py-4 px-3 border border-dashed border-3 rounded card-hover" style={{ minWidth: '200px' }}>
                                        <h5 className="text-muted text-uppercase fs-13">Absent Students <i className="ri-arrow-down-circle-line text-danger fs-18 float-end align-middle"></i></h5>
                                        <div className="d-flex align-items-center">
                                            <div className="bg-danger rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                                <i className="ri-user-4-fill display-6 text-white"></i>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h2 className="mb-0"><span className="counter-value" data-target={dashboardData?.attendanceData?.no_of_absent_students}>{dashboardData?.attendanceData?.no_of_absent_students}</span></h2>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className='mb-2' lg={6} xs={6}>
                                <div className="py-4 px-3 border border-dashed border-3 rounded card-hover" style={{ minWidth: '200px' }}>
                                    <h5 className="text-muted text-uppercase fs-13">Students On Leave<i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                                    <div className="d-flex align-items-center">
                                        <div className="bg-warning rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                            <i className="ri-information-fill display-6 text-white"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h2 className="mb-0"><span className="counter-value" data-target={dashboardData?.attendanceData?.no_of_leave_students}>{dashboardData?.attendanceData?.no_of_leave_students}</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className='mb-2' lg={6} xs={6}>
                                <Link to="/attendance">
                                    <div className="py-4 px-3 border border-dashed border-3 rounded card-hover" style={{ minWidth: '200px' }}>
                                        <h5 className="text-muted text-uppercase fs-13">Unmarked Attendance<i className="ri-arrow-down-circle-line text-danger fs-18 float-end align-middle"></i></h5>
                                        <div className="d-flex align-items-center">
                                            <div className="bg-dark rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                                <i className="ri-question-fill display-6 text-light"></i>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h2 className="mb-0"><span className="counter-value" data-target={dashboardData?.attendanceData?.no_of_unmarked_students}>{dashboardData?.attendanceData?.no_of_unmarked_students}</span></h2>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} sm={6} xs={12} className='mb-4 ' >
                        <Card className='p-2'>
                            <Card.Header className='m-0'>Attendance</Card.Header>
                            <Bar data={datas} options={{ responsive: true }} />
                        </Card>
                    </Col>
                </Row>
            )}
    <hr className='border border-dotted border-1 border-dark m-0' />
            <Row>
                <Col lg={6} sm={6} className='mb-4 mt-3' >
                    <Row>
                        <Col lg={6} xs={6} className='mb-4'>
                            <Link to="/users">
                                <div className="py-4 px-3 border border-dashed border-3 rounded card-hover" style={{ minWidth: '150px' }}>
                                    <h5 className="text-muted text-uppercase fs-13"> Admin</h5>
                                    <div className="d-flex align-items-center">
                                        <div className="bg-danger rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                            <i className="ri-admin-line display-6 text-white"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h2 className="mb-0">
                                                <span className="counter-value">
                                                    {dashboardData?.userData?.no_of_admins || 0}
                                                </span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>

                            </Link>
                        </Col>
                        <Col lg={6} xs={6} className='mb-4'>
                            <Link to="/admission">
                                <div className="py-4 px-3 border border-dashed border-3 rounded card-hover" style={{ minWidth: '150px' }}>
                                    <h5 className="text-muted text-uppercase fs-13"> Students</h5>
                                    <div className="d-flex align-items-center">
                                        <div className="bg-success rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                            <Student className="display-6 text-white" />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h2 className="mb-0">
                                                <span className="counter-value" >
                                                    {dashboardData?.userData?.no_of_students || 0}
                                                </span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col lg={6} xs={6} className='mb-4'>
                            <Link to="/staff">
                                <div className="py-4 px-3 border border-dashed border-3 rounded card-hover" style={{ minWidth: '150px' }}>
                                    <h5 className="text-muted text-uppercase fs-13"> Staff</h5>
                                    <div className="d-flex align-items-center">
                                        <div className="bg-warning rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>

                                            <ChalkboardTeacher className="display-6 text-white" />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h2 className="mb-0">
                                                <span className="counter-value" >
                                                    {dashboardData?.userData?.no_of_staffs || 0}
                                                </span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col lg={6} xs={6} className='mb-4'>
                            <Link to="/classes">
                                <div className="py-4 px-3 border border-dashed border-3 rounded card-hover" style={{ minWidth: '150px' }}>
                                    <h5 className="text-muted text-uppercase fs-13">Class</h5>
                                    <div className="d-flex align-items-center">
                                        <div className="bg-dark rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>

                                            <ChalkboardTeacher className="display-6 text-white" />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h2 className="mb-0">
                                                <span className="counter-value" >
                                                    {dashboardData?.classData?.total_class || 0}
                                                </span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col lg={6} xs={6} className='mb-4'>
                            <Link to="/enquiry">
                                <div className="py-4 px-3 border border-dashed border-3 rounded card-hover" style={{ minWidth: '150px' }}>
                                    <h5 className="text-muted text-uppercase fs-13">Enquiry</h5>
                                    <div className="d-flex align-items-center">
                                        <div className="bg-info rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>

                                            <EnvelopeSimple className="display-6 text-white" />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h2 className="mb-0">
                                                <span className="counter-value" >
                                                    {dashboardData?.enquiryData || 0}
                                                </span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                    </Row>
                </Col>
                <Col lg={6} sm={6} className='mb-4 mt-3' >
                    <Card className='p-3' >
                        <Card.Header className='m-0'>Total Users & Class</Card.Header>
                        <div className="d-flex justify-content-center" style={{ height: '400px' }}>
                            <PolarArea data={TotalUser} />
                        </div>
                    </Card>
                </Col>
            </Row>


            {AuthUser?._user?.role?.priority >= 80 && (
                <Row className='flex'>
                    <Col >
                        {/* <BarChart chartData={chartData} /> */}
                        <Card style={{ height: 'calc(100% - 1.5rem)' }}>
                            <Card.Header className='m-0'>
                                <Card.Title>
                                    <h5>Recent Transactions</h5>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body className='px-2 pt-2 pb-0'>
                                <TableResponsive
                                    showFilter={false}
                                    isPagination={false}
                                    isShowingPageLength={false}
                                    showCustomOptionPage={false}
                                    columns={columns}
                                    data={recentTransactions}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
        </>

    )
}

export default Index