import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';
import BreadCrumb from '../../../../components/common/BreadCrumb';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ASSET_URL, fee, student } from '../../../../helper/api_url';
import { swal } from '../../../../helper/swal';
import { formatDate } from '../../../../helper/formatDate';
import { StudentClass } from './StudentClass';
import { StudentAddress } from './StudentAddress';
import AddStudentFamilyModal from '../../family/add-student-family-modal';
import { Card, CardBody, CardFooter,  Modal } from 'react-bootstrap';
import { TableResponsive } from '../../../../components/common/TableResponsive';
import { setPreloader } from '../../../../features/Ui/uiSlice';
import { useDispatch } from 'react-redux';
export const StudentAdmissionProfile = () => {
    const [feeDueToBeDeleted, setFeeDueToBeDeleted] = useState(null);
    const dispatch = useDispatch();
    const { studentId } = useParams();
    const queryClient = useQueryClient();
    const { data: students = [], isLoading, isError, error } = useQuery({
        queryKey: ['students', 'profile', 'user', 'personal_details', studentId],
        queryFn: () => student.profile(studentId),
        staleTime: 20 * 60 * 1000,
        gcTime: 20 * 60 * 1000,
    });
    useEffect(() => { if (isError) swal.error(error.response ? error.response.data.message : error.message) }, [isError, error])
    //fee handle
    const { data: feeData = [], } = useQuery({
        queryKey: ['student', 'fee', studentId],
        queryFn: () => fee.StudentInfoFee(studentId),
        select: (data) => data?.data?.student_fee_info,
        staleTime: 20 * 60 * 1000,
        gcTime: 20 * 60 * 1000,
    });
    const handleDeleteFeeDue = (fee_id) => {
        dispatch(setPreloader({ loader: true, message: 'please wait ... ' }));
        fee.deleteFeeDue(fee_id).then(() => {
            queryClient.invalidateQueries(['student', 'fee', studentId]);
            setFeeDueToBeDeleted(null);
        }).catch((err) => { swal.error(err.response ? err.response.data.message : err.message) }).finally(() => { dispatch(setPreloader({ loader: false, message: "" })); })
    }
    const columns = useMemo(() => [
        {
            Header: "Fees name",
            accessor: "name",
            HeaderClass: '',
            DataClass: '',
        },
        {
            Header: "Total Amount",
            accessor: "imposed_amount",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
        },
        {
            Header: "Paid Amount",
            accessor: "fees_payment",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: ({ row }) => {
                const feesPayment = row.original.fees_payment;
                if (feesPayment && feesPayment.length > 0) {
                    return (
                        <>
                            {feesPayment.map((payment, index) => (
                                <span className='badge badge-outline-success mx-1' key={index}>{payment.paid_amt}</span>
                            ))}
                        </>

                    );
                }
                return "-";
            }
        },
        {
            Header: "Total Dues",
            accessor: "total_dues",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: ({ row }) => {
                const imposedAmount = row.original.imposed_amount;
                const lastPaymentDateObject = new Date(row.original.last_payment_date);
                const now = Date.now();
                if (lastPaymentDateObject < now) {
                    const feesPayment = row.original.fees_payment || [];
                    const totalPaid = feesPayment.reduce((total, payment) => total + (payment.paid_amt || 0), 0);
                    const totalDues = imposedAmount - totalPaid;
                    return totalDues > 0 ? <span className={'text-danger'}>{totalDues}</span> : <span className='text-success'>Fully Paid</span>;
                } else return "-"


            }
        },
        {
            Header: "Last Payment",
            HeaderClass: 'text-center',
            DataClass: 'text-end',
            Cell: (cell) => {
                return (<span>{formatDate(cell.row.original.last_payment_date)}</span>)
            }
        },
        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                return (<span>
                    <button
                        className='btn btn-sm btn-outline-danger'
                        onClick={() => setFeeDueToBeDeleted(cell.row.original)}
                    ><i className="bx bx-trash"></i></button>
                </span>)
            }
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                const imposedAmount = row.imposed_amount;
                const lastPaymentDateObject = new Date(row.last_payment_date);
                const now = Date.now();

                let dues = '-';
                if (lastPaymentDateObject < now) {
                    const feesPayment = row.fees_payment || [];
                    const totalPaid = feesPayment.reduce((total, payment) => total + (payment.paid_amt || 0), 0);
                    const totalDues = imposedAmount - totalPaid;
                    dues = totalDues > 0 ? <span className={'text-danger'}>{totalDues}</span> : <span className='text-success'>Fully Paid</span>;
                }
                const feesPayment = row.fees_payment || [];
                const totalPaid = feesPayment.reduce((total, payment) => total + (payment.paid_amt || 0), 0);
                const totalDues = imposedAmount - totalPaid;
                return (
                    <Card className="border shadow-sm">
                        <CardBody>
                            <div className="d-flex flex-column">
                                {/* Fee Name and Amount Section */}
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5 className="mb-0">{row.name}</h5>
                                    <span className="badge bg-info px-3 py-2 rounded-pill">
                                        ₹{imposedAmount}
                                    </span>
                                </div>

                                {/* Payment Details */}
                                <div className="d-flex flex-wrap gap-2 mb-3">
                                    <div className="bg-soft-success px-3 py-2 rounded">
                                        <small className="text-muted d-block">Paid Amount</small>
                                        <span className="fw-semibold">
                                            {feesPayment.length > 0 ? (
                                                feesPayment.map((payment, index) => (
                                                    <span key={index} className="me-1">
                                                        ₹{payment.paid_amt}
                                                        {index < feesPayment.length - 1 ? " +" : ""}
                                                    </span>
                                                ))
                                            ) : (
                                                "₹0"
                                            )}
                                        </span>
                                    </div>

                                    <div className={`px-3 py-2 rounded ${totalDues > 0 ? 'bg-soft-danger' : 'bg-soft-success'}`}>
                                        <small className="text-muted d-block">Dues</small>
                                        <span className="fw-semibold">
                                            {dues}
                                        </span>
                                    </div>
                                </div>

                                {/* Last Payment Date */}
                                <div className="d-flex justify-content-between border-top pt-2 align-items-center">
                                    <div className="">
                                        <small className="text-muted">Last Payment Date:</small>
                                        <span className="ms-2">{formatDate(lastPaymentDateObject)}</span>
                                    </div>
                                    <button
                                        className='btn btn-sm btn-outline-danger'
                                        onClick={() => setFeeDueToBeDeleted(row)}
                                    ><i className="bx bx-trash"></i></button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                )

            }
        }
    ]);
    return (
        <>
            <BreadCrumb title="Addmission Details" prevPage="Home" prevPath="/admission" />
            <div className="d-flex justify-content-end">
                <h2 className='pe-3'>{students?.data?.profile?.first_name} {students?.data?.profile?.last_name}</h2> {students?.data?.profile?.avatar ? <div className='d-flex justify-content-end'><span> <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={ASSET_URL + students?.data?.profile?.avatar} alt="User Avatar" /></span></div> : ''}
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-4 col-md-8 col-sm-10 col-xs-12">
                    <div className="card mt-4 shadow-sm">
                        <div className="card-header text-white text-center">
                            <h2>Student Details</h2>
                        </div>
                        <div className="card-body">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <div className="row justify-content-between align-items-center">
                                        <span className="col-5 fw-bold">Student Id</span>
                                        <span className="col-1 fw-bold">:</span>
                                        <span className='col-6'>#{students?.data?.profile?.id}</span>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="row justify-content-between align-items-center">
                                        <span className="col-5 fw-bold">Student Name</span>
                                        <span className="col-1 fw-bold">:</span>
                                        <span className='col-6'>{students?.data?.profile?.first_name} {students?.data?.profile?.last_name}</span>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="row justify-content-between align-items-center">
                                        <span className="col-5 fw-bold">Gender</span>
                                        <span className="col-1 fw-bold">:</span>
                                        <span className='col-6'>{students?.data?.profile?.gender}</span>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="row justify-content-between align-items-center">
                                        <span className="col-5 fw-bold">Date of Birth</span>
                                        <span className="col-1 fw-bold">:</span>
                                        <span className='col-6'>{formatDate(students?.data?.profile?.dob)}</span>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="row justify-content-between align-items-center">
                                        <span className="col-5 fw-bold">Email Id</span>
                                        <span className="col-1 fw-bold">:</span>
                                        <span className='col-6'>{students?.data?.profile?.email}</span>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="row justify-content-between align-items-center">
                                        <span className="col-5 fw-bold">Mobile</span>
                                        <span className="col-1 fw-bold">:</span>
                                        <span className='col-6'>{students?.data?.profile?.phone}</span>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="row justify-content-between align-items-center">
                                        <span className="col-5 fw-bold">Class</span>
                                        <span className="col-1 fw-bold">:</span>
                                        <span className='col-6'>
                                            {Array.isArray(students?.data?.profile?.courses) && students.data.profile.courses.length > 0 ? (
                                                <>
                                                    {students.data.profile.courses[0].name}
                                                </>
                                            ) : (
                                                <StudentClass studentId={studentId} />
                                            )}
                                        </span>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="row justify-content-between align-items-center">
                                        <span className="col-5 fw-bold">Street Address</span>
                                        <span className="col-1 fw-bold">:</span>
                                        <span className="col-6">
                                            {Array.isArray(students?.data?.profile?.addresses) && students.data.profile.addresses.length > 0 ? (
                                                <>
                                                    {students.data.profile.addresses[0].addr1}  ,
                                                    {students.data.profile.addresses[0].city}, {students.data.profile.addresses[0].district} <br />
                                                    {students.data.profile.addresses[0].state} ({students.data.profile.addresses[0].pin})
                                                </>
                                            ) : (
                                                <StudentAddress studentId={studentId} />
                                            )}
                                        </span>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-8 col-sm-10 col-xs-12">
                    <div className="card mt-4 shadow-sm">
                        <div className="card-header text-white text-center">
                            <h2>Family Details </h2>
                        </div>
                        <div className="card-body">
                            <ul className="list-group list-group-flush">
                                {
                                    <>

                                        {students?.data?.profile?.personal_details?.family ? (
                                            <>
                                                <li className="list-group-item">
                                                    <div className="row justify-content-between align-items-center">
                                                        <span className="col-5 fw-bold">Father Name</span>
                                                        <span className="col-1 fw-bold">:</span>
                                                        <span className='col-5'><span>{students?.data?.profile?.personal_details?.family?.father_name} <span className='badge badge-outline-success ms-2 text-nowrap'>{students?.data?.profile?.personal_details?.family?.father_qualification}</span></span></span>
                                                    </div>
                                                </li>
                                                <li className="list-group-item">
                                                    <div className="row justify-content-between align-items-center">
                                                        <span className="col-5 fw-bold">Occupation</span>
                                                        <span className="col-1 fw-bold">:</span>
                                                        <span className='col-5'>{students?.data?.profile?.personal_details?.family?.father_occupation}</span>
                                                    </div>
                                                </li>
                                                <li className="list-group-item">
                                                    <div className="row justify-content-between align-items-center">
                                                        <span className="col-5 fw-bold">Mobile</span>
                                                        <span className="col-1 fw-bold">:</span>
                                                        <span className='col-5'>{students?.data?.profile?.personal_details?.family?.father_mobile}</span>
                                                    </div>
                                                </li>
                                                <li className="list-group-item">
                                                    <div className="row justify-content-between align-items-center">
                                                        <span className="col-5 fw-bold">Aadhar No.</span>
                                                        <span className="col-1 fw-bold">:</span>
                                                        <span className='col-5'>{students?.data?.profile?.personal_details?.family?.father_aadhaar}</span>
                                                    </div>
                                                </li>

                                                <hr />
                                                <li className="list-group-item">
                                                    <div className="row justify-content-between align-items-center">
                                                        <span className="col-5 fw-bold">Mother Name</span>
                                                        <span className="col-1 fw-bold">:</span>
                                                        <span className='col-5'>{students?.data?.profile?.personal_details?.family?.mother_name}</span>
                                                    </div>
                                                </li>
                                                <li className="list-group-item">
                                                    <div className="row justify-content-between align-items-center">
                                                        <span className="col-5 fw-bold">Occupation</span>
                                                        <span className="col-1 fw-bold">:</span>
                                                        <span className='col-5'>{students?.data?.profile?.personal_details?.family?.mother_occupation}</span>
                                                    </div>
                                                </li>
                                                <li className="list-group-item">
                                                    <div className="row justify-content-between align-items-center">
                                                        <span className="col-5 fw-bold">Mobile</span>
                                                        <span className="col-1 fw-bold">:</span>
                                                        <span className='col-5'>{students?.data?.profile?.personal_details?.family?.mother_mobile}</span>
                                                    </div>
                                                </li>
                                                <li className="list-group-item">
                                                    <div className="row justify-content-between align-items-center">
                                                        <span className="col-5 fw-bold">Aadhar No.</span>
                                                        <span className="col-1 fw-bold">:</span>
                                                        <span className='col-5'>{students?.data?.profile?.personal_details?.family?.mother_aadhaar}</span>
                                                    </div>
                                                </li>


                                            </>
                                        ) : (
                                            <AddStudentFamilyModal studentId={studentId} />
                                        )}


                                    </>
                                }

                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-8 col-sm-10 col-xs-12">
                    <div className="card mt-4 shadow-sm">
                        <div className="card-header text-white text-center">
                            <h2>Fees Details</h2>
                        </div>
                        <TableResponsive
                            customPageSize={1000}
                            isPagination={false}
                            showCustomOptionPage={false}
                            columns={columns}
                            data={feeData} noRecord="No Record Found"
                            showFilter={false}
                            tableClass="table dt-responsive nowrap align-middle"
                        // tableAllHead='bg-black text-light'
                        />

                        <CardFooter className='d-flex align-items-center justify-content-between w-100'>
                            <div>
                                <p className='d-inline border me-4 p-2 rounded bg-soft-primary'>
                                    Total Fees : {feeData.reduce((a, f) => a + parseFloat(f.imposed_amount), 0)}
                                </p>
                                <p className='d-inline border p-2 rounded bg-soft-success' >
                                    Total Paid : {feeData.reduce((acc, fee) => {
                                        const total_payment = fee.fees_payment.reduce((totalPayment, fp) => parseFloat(totalPayment) + parseFloat(fp.paid_amt), 0);
                                        return acc + total_payment;
                                    }, 0)}
                                </p>
                            </div>
                            <div>
                                <p className='border p-2 mt-3 rounded bg-soft-danger'>
                                    Overdues : {
                                        feeData.reduce((acc, fee) => {
                                            const lastPaymentDateObject = new Date(fee.last_payment_date);
                                            const now = Date.now();
                                            if (lastPaymentDateObject < now) {
                                                const total_payment = fee.fees_payment.reduce((totalPayment, fp) => parseFloat(totalPayment) + parseFloat(fp.paid_amt), 0);
                                                return parseFloat(acc) + parseFloat(fee.imposed_amount) - parseFloat(total_payment);
                                            } else return acc + 0;

                                        }, 0)
                                    }
                                </p>
                            </div>
                        </CardFooter>

                        <Modal
                            className="fade"
                            size=''
                            centered={true}
                            backdrop="static"
                            show={feeDueToBeDeleted}
                            onHide={() => setFeeDueToBeDeleted(null)}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <h5>Dues Delete ?</h5>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure you want to delete this fee due?</p>
                                <p className='text-warning mb-2'>{feeDueToBeDeleted?.name}</p>
                                <p className='text-danger fs-5'> Fees Dues/Amount : {feeDueToBeDeleted?.imposed_amount - feeDueToBeDeleted?.fees_payment.reduce((acc, f) => acc + f.paid_amt, 0)}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className='btn btn-sm btn-outline-secondary' onClick={() => setFeeDueToBeDeleted(null)}>Cancel</button>
                                <button className='btn btn-sm btn-outline-danger' onClick={() => handleDeleteFeeDue(feeDueToBeDeleted.id)}>Delete</button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>

        </>
    )
}
