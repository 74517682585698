import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useModalHandler, useWebCam } from '../../../../helper/custom_hook';
import dummyUser from "../../../../assets/images/users/user-dummy-img.jpg";
import { allUser, ASSET_URL } from '../../../../helper/api_url';
import Camera from '../../../../components/common/Camera';
import CustomSelect from '../../../../components/CustomSelect';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { swal } from '../../../../helper/swal';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../../features/Ui/uiSlice';
export const UpdateStudentProfile = ({ data }) => {
    const { genders,userProfile, setUserProfile } = useModalHandler();
    const dispatch=useDispatch();
    const { webcam, toggleWebcam, setCapturedImageToInput } = useWebCam(setUserProfile);
    const onSave = (f) => setCapturedImageToInput(f, document.getElementById("profile-img-file-input"));
    useEffect(() => {
        const viewImage = ASSET_URL + data.avatar;
        setUserProfile(viewImage);
    }, [data.avatar]);
    const queryClient = useQueryClient();
    const mutation = useMutation({
		mutationFn: (formData) => allUser.update(formData),
        onMutate: () => {dispatch(setPreloader({ loader: true, message: 'please wait ... ' }));},
		onSuccess: (res) => {queryClient.invalidateQueries(['students', data?.id]);swal.success(res.data.message);},
		onError: (err) => {swal.error(err.response ? err.response.data.message : err.message);},
        onSettled: () => {dispatch(setPreloader({ loader: false, message: '' }));},
	});
    const handleUpdateStudentProfile=(e)=>{
        e.preventDefault();
        const formData = new FormData(e.target);
		mutation.mutate(formData);
    }
    return (
        <>
            <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12">
                <div className="card mt-4 shadow-sm">
                    <div className="card-header text-white text-center">
                        <h2 className='mb-1 text-center text-primary'>Profile</h2>
                    </div>
                    <form onSubmit={handleUpdateStudentProfile}>
                        <div className="card-body">
                            <Row>
                                <Col className="text-center">
                                    <div className="profile-user position-relative d-inline-block mx-auto mb-2">
                                        <label htmlFor="profile-img-file-input" className="cursor-pointer" >
                                            <img src={userProfile ? userProfile : dummyUser} htmlFor="profile-img-file-input" className="rounded-circle avatar-lg img-thumbnail user-profile-image cursor" alt="" />
                                        </label>
                                        <div className="avatar-xs ms-0 me-5 mb-2 rounded-circle profile-photo-edit" style={{ marginLeft: "31px" }} >
                                            <input id="profile-img-file-input" type="file" name="avatar" className="profile-img-file-input" accept="image/png, image/jpeg, image/jpg" onChange={(e) => onSave(e.target.files[0])} defaultValue="" />
                                        </div>
                                        <div className="avatar-xs rounded-circle profile-photo-edit">
                                            <div onClick={toggleWebcam} className="avatar-title rounded-circle bg-light text-body"  > <i className="ri-camera-fill"></i>  </div>
                                        </div>
                                    </div>
                                    {webcam ? (<div className="position-relative top-0 bottom-0 d-flex justify-content-center align-items-center start-0 end-0 modal-content border-0" style={{ zIndex: 9 }} ><Camera onSave={(f) => { onSave(f); toggleWebcam(); }} onClose={() => toggleWebcam()} /></div>) : null}
                                </Col>
                            </Row>
                            <div className='row justify-content-between'>
                                <input type="hidden" name="user_id" defaultValue={data?.id} />
                                <div className="col-6">
                                    <div>
                                        <label htmlFor="firstName" className="form-label">First Name</label>
                                        <input type="text" className="form-control" id='firstName' name="first_name" defaultValue={data?.first_name} placeholder="Enter firstname" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div>
                                        <label htmlFor="lastName" className="form-label">Last Name</label>
                                        <input type="text" className="form-control" id='lastName' name="last_name" defaultValue={data?.last_name} placeholder="Enter lastname" />
                                    </div>
                                </div>
                                <div className="col-6 mt-2">
                                    <div>
                                        <label htmlFor="gender" className="form-label">Gender</label>
                                        <CustomSelect options={genders} defaultValue={{ value: data?.gender, label: data?.gender }} isSearchable name='gender' />
                                    </div>
                                </div>
                                <div className="col-6 mt-2">
                                    <div>
                                        <label htmlFor="dob" className="form-label">Date of Birth </label>
                                        <input type="date" className="form-control" id='dob' name="dob" defaultValue={data?.dob} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='d-flex justify-content-center'>
                            <button type="submit" className="btn btn-primary">Update Profile</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
