import React, { useState } from 'react';
import { useModalHandler } from '../../../../helper/custom_hook';
import { Modal, Row } from 'react-bootstrap';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { userAddress } from '../../../../helper/api_url';
import { swal } from '../../../../helper/swal';

export const StudentAddress = ({studentId}) => {
  const { toggleModal, status } = useModalHandler();
  const queryClient = useQueryClient();
  const [isCurrentSame, setIsCurrentSame] = useState(true);
  const [permanentAddress, setPermanentAddress] = useState({addr1_p: '',addr2_p: '',city_p: '',district_p: '',state_p: '',pin_p: '',});
  const [currentAddress, setCurrentAddress] = useState({addr1_c: '',addr2_c: '',city_c: '',district_c: '',state_c: '',pin_c: '',});

  const handlePermanentAddressChange = (e) => {
    const { name, value } = e.target;
    setPermanentAddress((prev) => ({ ...prev,[name]: value,}));
    if (isCurrentSame) { setCurrentAddress((prev) => ({...prev,[name]: value,}));}};

  const handleCurrentAddressChange = (e) => {
    const { name, value } = e.target;
    setCurrentAddress((prev) => ({...prev,[name]: value,}));
  };

  const mutation = useMutation({
    mutationFn: (formData) => userAddress.add(formData),
    onSuccess: (res) => {
        queryClient.invalidateQueries(["students"]);
        swal.success(res.data.message);
        toggleModal();
    },
    onError: (err) => { swal.error(err.response ? err.response.data.message : err.message); }
});
const handleSubmitAddress = (e) => {
  e.preventDefault();

  // Flatten the data structure
  const formData = {
    user_id: studentId,
    type: isCurrentSame ? "permanent" : "current",
    addr1: permanentAddress.addr1_p,
    addr2: 'Null',
    city: permanentAddress.city_p,
    district: permanentAddress.district_p,
    state: permanentAddress.state_p,
    pin: permanentAddress.pin_p,
  };

  // If current address is different, add its fields
  if (!isCurrentSame) {
    formData.addr1 = currentAddress.addr1_c;
    formData.addr2 = currentAddress.addr2_c;
    formData.city = currentAddress.city_c;
    formData.district = currentAddress.district_c;
    formData.state = currentAddress.state_c;
    formData.pin = currentAddress.pin_c;
  }

  // Send the data to the mutation
  mutation.mutate(formData);
};


  return (
    <>
      <button onClick={toggleModal}className="btn btn-outline-success rounded-circle d-flex justify-content-center align-items-center shadow"style={{ width: '30px', height: '30px', padding: '0' }}><i className="bx bx-plus" style={{ fontSize: '24px' }}></i></button>
      <Modal className="fade" centered backdrop="static" show={status} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title><h5>Address</h5></Modal.Title></Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitAddress}>
            <Row>
              <div className="mb-4"><h5 className="mb-1 text-center text-primary">Permanent Address</h5></div>
              <div className="mb-2">
                <label htmlFor="addr1_p" className="form-label">Street Address <span className="text-danger">*</span> </label>
                <textarea name="addr1_p"id="addr1_p"className="form-control"value={permanentAddress.addr1_p}onChange={handlePermanentAddressChange}/>
              </div>
              <div className="col-6 mb-2">
                <label htmlFor="city_p" className="form-label">City</label>
                <input type="text"name="city_p"id="city_p"className="form-control"value={permanentAddress.city_p}onChange={handlePermanentAddressChange}/>
              </div>
              <div className="col-6 mb-2">
                <label htmlFor="district_p" className="form-label">District</label>
                <input type="text" name="district_p" id="district_p"className="form-control"value={permanentAddress.district_p}onChange={handlePermanentAddressChange}/>
              </div>
              <div className="col-6 mb-2">
                <label htmlFor="state_p" className="form-label">State</label>
                <input type="text"name="state_p"id="state_p"className="form-control" value={permanentAddress.state_p}onChange={handlePermanentAddressChange}/>
              </div>
              <div className="col-6 mb-2">
                <label htmlFor="pin_p" className="form-label">Pin</label>
                <input type="number"name="pin_p"id="pin_p"className="form-control"value={permanentAddress.pin_p} onChange={handlePermanentAddressChange} />
              </div>
            </Row>
            <hr />
            <div className="card p-3 shadow-sm">
              <div className="d-flex align-items-center justify-content-between">
                <span className="fw-bold">Is your current address the same?</span>
                <div className="d-flex align-items-center">
                  <div className="form-check me-3">
                    <input type="radio"id="yes_same"name="address_same"className="form-check-input" checked={isCurrentSame}onChange={() => setIsCurrentSame(true)}/>
                    <label htmlFor="yes_same" className="form-check-label">Yes</label>
                  </div>
                  <div className="form-check">
                    <input type="radio"id="no_same"name="address_same"className="form-check-input"checked={!isCurrentSame}onChange={() => setIsCurrentSame(false)}/>
                    <label htmlFor="no_same" className="form-check-label">No</label>
                  </div>
                </div>
              </div>
            </div>

            <div className={`row ${isCurrentSame ? 'd-none' : 'd-block'}`}>
              <div className="my-2">
                <h5 className="mb-1 text-center text-primary">Current Address</h5>
              </div>
              <div className='row'>

              <div className="col-12 mb-2">
                <label htmlFor="addr1_c" className="form-label"> Street Address <span className="text-danger">*</span></label>
                <textarea name="addr1_c"id="addr1_c"className="form-control"value={currentAddress.addr1_c} onChange={handleCurrentAddressChange}/>
              </div>
              <div className="col-6 mb-2">
                <label htmlFor="city_c" className="form-label">City</label>
                <input type="text" name="city_c" id="city_c"className="form-control" value={currentAddress.city_c} onChange={handleCurrentAddressChange}/>
              </div>
              <div className="col-6 mb-2">
                <label htmlFor="district_c" className="form-label">District</label>
                <input
                  type="text" name="district_c"id="district_c"className="form-control" value={currentAddress.district_c} onChange={handleCurrentAddressChange} />
              </div>
              <div className="col-6 mb-2">
                <label htmlFor="state_c" className="form-label">State</label>
                <input type="text" name="state_c"  id="state_c" className="form-control" value={currentAddress.state_c} onChange={handleCurrentAddressChange} />
              </div>
              <div className="col-6 mb-2">
                <label htmlFor="pin_c" className="form-label">Pin</label>
                <input type="number" name="pin_c" id="pin_c" className="form-control" value={currentAddress.pin_c}  onChange={handleCurrentAddressChange} />
              </div>
            </div>
              </div>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <button type="submit" className="btn btn-primary">Save</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
